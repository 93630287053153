const PlayList = [
  { id: 1, name: "Top hit 2022" },
  { id: 2, name: "Dance" },
  { id: 3, name: "Relaxing Music" },
  { id: 4, name: "Instrumental Music" },
  { id: 5, name: "Subliminal Music" },
  { id: 6, name: "Binaural" },
];

export { PlayList };
